import React from "react"

import InfoPrimary from './InfoPrimary';

export default function InfoMain({ children }) {
	
  return (<>

   
    <InfoPrimary />
    
    </>);
}
