import React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet';
import Mouse from '../components/mouse/Mouse'
import InfoMain from '../components/info/InfoMain'

const InfoTemplate = props => {
    return (
      <Layout data={props} pageID={'page-info'}>
        <Helmet>
          <meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;" />
          <meta charSet="utf-8" />
          <title>Info - Public School</title>
          <meta name="description" content="Public School is part of the Czarnowski Collective - a full-service brand experience agency. We build people driven Experiences." />
          <meta name="referrer" content="origin" />
        </Helmet>

         <section id="info-page" className="container info-page">
          <InfoMain/>
        </section>
        <Mouse />

      </Layout>
    )
}

export default InfoTemplate
