import React, { Component } from "react";
import Logo from "../../images/svg/logo.svg";
import Clogo from "../../images/svg/cc-logo-new.svg";

import Infusion from "../../images/svg/infusion.svg";
import Assembly from "../../images/svg/assembly-logo.svg";
import Czarnowski from "../../images/svg/czarnowski-logo-white.svg";

class InfoPrimary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollingPos: 0,
    };
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      let pageWidth = window.innerWidth;

      document.body.style.position = "relative";

      let HL = document.getElementById("hero-left");
      let HR = document.getElementById("hero-right");
      let CL = document.getElementById("col-left");
      let CR = document.getElementById("col-right");
      let CO = document.getElementById("col-opacity");
      let BT = document.getElementById("btm-text");
      let BS = document.getElementById("btm-slider");
      let Footer = document.getElementById("info-footer");

      //scrollSpeeds
      let scrollingPosPerm = 0;
      let scrollingPos = 0;

      //triggers
      let heroTrigger = 94;
      let colTrigger = 300;
      let colMidTrigger = 500;
      let colMid2Trigger = 725;
      let btmTrigger1 = 900;
      let btmTrigger2 = 1200;
      let btmTrigger3 = 2453;

      //exact spots for elements
      let HLFirstSpot = 12.917;
      let HLSecondSpot = 22;
      let CLFirstSpot = 160;
      let CLSecondSpot = 100;
      let CLThirdSpot = 180;
      let CRFirstSpot = 185;
      let BTFirstSpot = 10;
      let BTSecondSpot = 300;
      let BTThirdSpot = 300;
      let scrollSpeed = 1;

      //changing text options
      let popTextRandoms = [
        "creative storyteller",
        "big idea factory",
        "creative studio",
        "flashlight in the dark",
        "big-stage performer",
        "advertising line cook",
        "tremendous ally",
      ];
      let changingText = document.getElementById("changing-text");
      //setting when the parralex triggers
      if (pageWidth >= 1680) {
        //triggers
        heroTrigger = pageWidth / 14;
        colTrigger = 400;
        colMidTrigger = pageWidth / 3;
        colMid2Trigger = pageWidth / 2.11;
        btmTrigger1 = 900;
        btmTrigger2 = pageWidth / 1.37;
        btmTrigger3 = pageWidth / 0.87;

        HLFirstSpot = 12.917;
        HLSecondSpot = pageWidth / 76.7;
        CLFirstSpot = pageWidth / 10.5;
        CLSecondSpot = pageWidth / 18;
        CLThirdSpot = pageWidth / 9.1;
        CRFirstSpot = pageWidth / 9.1;
        BTFirstSpot = pageWidth / 10.8;
        BTSecondSpot = pageWidth / 123;
        BTThirdSpot = pageWidth / 7;
      } else if (pageWidth < 1680 && pageWidth >= 1630) {
        //triggers
        heroTrigger = pageWidth / 15;
        colTrigger = 400;
        colMidTrigger = pageWidth / 3;
        colMid2Trigger = pageWidth / 2.11;
        btmTrigger1 = 900;
        btmTrigger2 = pageWidth / 1.33;
        btmTrigger3 = pageWidth / 0.85;

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 97;
        CLThirdSpot = 188;
        CRFirstSpot = 190;
        BTFirstSpot = 160;
        BTSecondSpot = 14;
        BTThirdSpot = 245;
      } else if (pageWidth < 1630 && pageWidth >= 1580) {
        //triggers
        heroTrigger = 110;
        colMidTrigger = 540;
        colMid2Trigger = 780;
        btmTrigger2 = 1200;
        btmTrigger3 = 1910;

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 96;
        CLThirdSpot = 189;
        CRFirstSpot = 188.5;
        BTFirstSpot = 160;
        BTSecondSpot = 14;
        BTThirdSpot = 245;
      } else if (pageWidth < 1580 && pageWidth >= 1530) {
        //triggers
        heroTrigger = 110;
        colMidTrigger = 540;
        colMid2Trigger = 780;
        btmTrigger2 = 1170;
        btmTrigger3 = 1840;

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 191;
        CRFirstSpot = 190;
        BTFirstSpot = 160;
        BTSecondSpot = 14;
        BTThirdSpot = 246;
      } else if (pageWidth < 1530 && pageWidth >= 1480) {
        //triggers
        heroTrigger = 110;
        colMidTrigger = 520;
        colMid2Trigger = 750;
        btmTrigger2 = 1130;
        btmTrigger3 = 1790;

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 190;
        CRFirstSpot = 190;
        BTFirstSpot = 160;
        BTSecondSpot = 14;
        BTThirdSpot = 248;
      } else if (pageWidth < 1480 && pageWidth >= 1430) {
        //triggers
        heroTrigger = 104;
        colMidTrigger = 500;
        colMid2Trigger = 720;
        btmTrigger2 = 1090;
        btmTrigger3 = 1740;

        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 190;
        CRFirstSpot = 190;
        BTFirstSpot = 160;
        BTSecondSpot = 14;
        BTThirdSpot = 250;
      } else if (pageWidth < 1430 && pageWidth >= 1380) {
        //triggers
        heroTrigger = 104;
        colMidTrigger = 480; //- 20
        colMid2Trigger = 700; //-30
        btmTrigger2 = 1040; // -40
        btmTrigger3 = 1690; // -50

        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 190;
        CRFirstSpot = 190;
        BTFirstSpot = 160;
        BTSecondSpot = 16;
        BTThirdSpot = 252; // + 2
      } else if (pageWidth < 1380 && pageWidth >= 1330) {
        //triggers
        heroTrigger = 94;
        colMidTrigger = 460; //- 20
        colMid2Trigger = 680; //-30
        btmTrigger2 = 1000; // -40
        btmTrigger3 = 1680; // -50

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 96;
        CLThirdSpot = 191;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 17;
        BTThirdSpot = 260;
      } else if (pageWidth < 1330 && pageWidth >= 1280) {
        //triggers
        heroTrigger = 94;
        colMidTrigger = 440; //- 20
        colMid2Trigger = 650; //-30
        btmTrigger2 = 960; // -40
        btmTrigger3 = 1670; // -50

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 96;
        CLThirdSpot = 191;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 17;
        BTThirdSpot = 268;
      } else if (pageWidth < 1280 && pageWidth >= 1230) {
        //triggers
        heroTrigger = 94;
        colMidTrigger = 420; //- 20
        colMid2Trigger = 620; //-30
        btmTrigger2 = 930; // -40
        btmTrigger3 = 1630; // -50

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 97;
        CLThirdSpot = 191;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 17;
        BTThirdSpot = 274;
      } else if (pageWidth < 1230 && pageWidth >= 1180) {
        //triggers
        heroTrigger = 86;
        colMidTrigger = 400; //- 20
        colMid2Trigger = 610; //-30
        btmTrigger2 = 880; // -40
        btmTrigger3 = 1610; // -50

        HLFirstSpot = 12.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 98;
        CLThirdSpot = 193;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 16;
        BTThirdSpot = 278;
      } else if (pageWidth < 1180 && pageWidth >= 1130) {
        //triggers
        heroTrigger = 78; // -8
        colMidTrigger = 400; //- 20
        colMid2Trigger = 550; //-30
        btmTrigger1 = 600;
        btmTrigger2 = 860; // -40
        btmTrigger3 = 1610; // -50

        HLFirstSpot = 16.917;
        HLSecondSpot = 22;
        CLFirstSpot = 160;
        CLSecondSpot = 97;
        CLThirdSpot = 186;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 16;
        BTThirdSpot = 288;
      } else if (pageWidth < 1130 && pageWidth >= 1080) {
        //triggers
        heroTrigger = 60; // -8
        colMidTrigger = 400; //- 20
        colMid2Trigger = 540; //-30
        btmTrigger1 = 600;
        btmTrigger2 = 810; // -40
        btmTrigger3 = 1620; // -50

        HLFirstSpot = 18.917;
        HLSecondSpot = 24;
        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 186;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 20;
        BTThirdSpot = 306;
      } else if (pageWidth < 1080 && pageWidth >= 1030) {
        //triggers
        heroTrigger = 54; // -8
        colMidTrigger = 360; //- 20
        colMid2Trigger = 500; //-30
        btmTrigger1 = 600;
        btmTrigger2 = 770; // -40
        btmTrigger3 = 1600; // -50

        HLFirstSpot = 18.917;
        HLSecondSpot = 24;
        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 183;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 20;
        BTThirdSpot = 316;
      } else if (pageWidth < 1030 && pageWidth >= 981) {
        //triggers
        heroTrigger = 54; // -8
        colMidTrigger = 360; //- 20
        colMid2Trigger = 490; //-30
        btmTrigger1 = 600;
        btmTrigger2 = 730; // -40
        btmTrigger3 = 1580; // -50

        HLFirstSpot = 18.917;
        HLSecondSpot = 24;
        CLFirstSpot = 160;
        CLSecondSpot = 95;
        CLThirdSpot = 185;
        CRFirstSpot = 189;
        BTFirstSpot = 160;
        BTSecondSpot = 20;
        BTThirdSpot = 328;
      } else if (pageWidth < 981 && pageWidth >= 920) {
        //triggers
        heroTrigger = 94;
        scrollSpeed = 2;
      } else if (pageWidth < 920 && pageWidth >= 768) {
        //triggers
        heroTrigger = 110;
        scrollSpeed = 2;
      } else if (pageWidth < 768 && pageWidth >= 576) {
        //triggers
        heroTrigger = 94;
        scrollSpeed = 2.5;
      } else if (pageWidth < 576 && pageWidth >= 400) {
        //triggers
        heroTrigger = 64;
        scrollSpeed = 2.3;
        btmTrigger3 = 480; // -50
      } else {
        //triggers
        heroTrigger = 64;
        scrollSpeed = 2.3;
      }
      window.addEventListener("scroll", function (e) {
        // - (-1) goes down and -1 goes up
        //mobile
        if (pageWidth <= 1200) {
          if (scrollingPosPerm > 0 && scrollingPosPerm < heroTrigger) {
            if (scrollingPos % 4 === 0) {
              const onlyValidValues = popTextRandoms.filter(
                (f) => f !== changingText.innerHTML
              );
              changingText.innerHTML =
                onlyValidValues[
                  Math.floor(Math.random() * onlyValidValues.length)
                ];
            }
          } else {
            BS.style.transform =
              "translateY(" + scrollingPos * scrollSpeed + "px)";
          }
        }
        //desktop
        else {
          if (scrollingPosPerm > 0 && scrollingPosPerm < heroTrigger) {
            //hero
            HL.style.position = "fixed";
            HL.style.top = HLFirstSpot + "rem";

            HR.style.transform = "translateY(" + scrollingPos + "px)";

            //changing text
            if (scrollingPos % 4 === 0) {
              const onlyValidValues = popTextRandoms.filter(
                (f) => f !== changingText.innerHTML
              );
              changingText.innerHTML =
                onlyValidValues[
                  Math.floor(Math.random() * onlyValidValues.length)
                ];
            }
          } else if (
            scrollingPosPerm >= heroTrigger &&
            scrollingPosPerm < colTrigger
          ) {
            //static variables
            HL.style.position = "absolute";
            HL.style.top = HLSecondSpot + "rem";
            CL.style.transform = "translateY(" + scrollingPos + "px)";
            CO.style.display = "none";
          } else if (
            scrollingPosPerm >= colTrigger &&
            scrollingPosPerm < colMidTrigger
          ) {
            CL.style.transform = "translateY(" + scrollingPos + "px)";
            CO.style.display = "block";
          } else if (
            scrollingPosPerm >= colMidTrigger &&
            scrollingPosPerm < colMid2Trigger
          ) {
            // static variables
            CL.style.position = "fixed";
            if (pageWidth > 1700) {
              CL.style.left = "25.6rem";
              CL.style.top = CLSecondSpot - 7 + "rem";
            } else {
              CL.style.top = CLSecondSpot + "rem";
            }
            CR.style.transform = "translateY(" + scrollingPos + "px)";
            CO.style.display = "block";
          } else if (
            scrollingPosPerm >= colMid2Trigger &&
            scrollingPosPerm < btmTrigger1
          ) {
            CL.style.position = "absolute";
            if (pageWidth > 1700) {
              CL.style.left = "22.4rem";
              CL.style.top = CLThirdSpot - 6.2 + "rem";
              CR.style.top = CRFirstSpot - 5 + "rem";
            } else {
              CL.style.top = CLThirdSpot + "rem";
              CR.style.top = CRFirstSpot + "rem";
            }
            CR.style.position = "absolute";
            CO.style.display = "none";
          } else if (
            scrollingPosPerm >= btmTrigger1 &&
            scrollingPosPerm < btmTrigger2
          ) {
            CL.style.position = "absolute";
            BT.style.position = "absolute";
            BT.style.top = BTFirstSpot + "rem";
          } else if (
            scrollingPosPerm >= btmTrigger2 &&
            scrollingPosPerm < btmTrigger3
          ) {
            BT.style.position = "fixed";
            BT.style.top = BTSecondSpot + "rem";
            BS.style.transform = "translateY(" + scrollingPos + "px)";
          } else if (scrollingPosPerm >= btmTrigger3) {
            BT.style.position = "absolute";
            BT.style.top = BTThirdSpot + "rem";
          }
        }

        scrollingPosPerm = document.body.getBoundingClientRect().top * -0.5;
        scrollingPos = document.body.getBoundingClientRect().top * 1;
      });
    }
  }

  render() {
    return (
      <div className="info-main_Wrapper">
        <div id="hero-left" className="hero-left">
          <div className="hero-left_text">
            <p>
              Public School is a<br />
              <span id="changing-text">creative storyteller</span>
              <br />
              that connects new audiences and culture.
            </p>
          </div>
        </div>

        <div id="hero-right" className="hero-right">
          <div className="hero-right_text">
            <p>
              Public School is part of Czarnowski Collective — a cooperative of
              four specialized studios, each with unique capabilities, but a
              unified approach to building stand out brand experiences: We
              wonder.
            </p>
          </div>
        </div>

        <div id="col-left" className="col-left">
          <a
            href="https://www.czarnowskicollective.com"
            target="_blank"
            rel="noreferrer"
            className={"iconNextArrow"}
          >
            <div className={"iconNextArrow"}>
              <Clogo className={"iconNextArrow"} style={{pointerEvents: "none"}} />
            </div>
          </a>
        </div>

        <div id="col-right" className="col-right">
          <a
            className={"iconNextArrow"}
            href="https://www.czarnowski.com"
            target="_blank"
            rel="noreferrer">
            <div className={"info-col_scrolling iconNextArrow"}>
              <Czarnowski className="czar-svg" />
              <span className={"iconNextArrow"}>
                Exhibit &amp; Event Studio
              </span>
            </div>
          </a>
          <div className="info-col_scrolling">
            <Logo />
            <span>Creative Studio</span>
          </div>
          <div className="info-col_scrolling">
            <Infusion />
            <span>Digital &amp; Visualization Studio</span>
          </div>

          <a
            className={"iconNextArrow"}
            href="https://byassembly.com/"
            target="_blank"
            rel="noreferrer">
            <div className={"info-col_scrolling iconNextArrow"}>
              <Assembly className="assembly-svg" />
              <span className={" assembly-text iconNextArrow"}>
                Temporary Structures Studio
              </span>
            </div>
          </a>
        </div>

        <div id="col-opacity" className="col-opacity"></div>

        <div id="btm-text" className="btm-text">
          <div className="btm-text_wrapper">
            <p>
              Tell the best story in the most relevant way possible{" "}
              <span></span>
            </p>
          </div>
        </div>

        <div id="btm-slider" className="btm-slider">
          <div className="info-btm_block">
            <p>
              This goal is always the same. It's the means and methods that
              shift from project to project — from a new brand identity that
              engages a community to an experiential campaign fueled by social
              interaction and content.
            </p>
            <div className="info-content_grid">
              <h4>We Offer</h4>
              <div className="block-right_wrap">
                <span>Brand Strategy and Planning</span>
                <span>Creative Design and Production</span>
                <span>Talent and Influencer Management</span>
              </div>
            </div>
            <div className="info-content_grid">
              <h4>To Create</h4>
              <div className="block-right_wrap">
                <span>Branding</span>
                <span>Integrated Campaigns</span>
                <span>Experiential Engagements</span>
                <span>Sponsorship Activations</span>
              </div>
            </div>
          </div>
        </div>

        <section
          id="info-footer"
          className="container footerWrapper info-footer">
          <div className="footerTop">
            <div className="mobileFooterText">
              <span>
                It appears you've hit the bottom. Only two ways for you to go
                from here:&nbsp;
                <a className={"iconNextArrow"} href="/">
                  See our work
                </a>{" "}
                or{" "}
                <a className={"iconNextArrow"} href="/contact">
                  say hello.
                </a>
              </span>
              <span> </span>
            </div>
            <p>
              <span>
                It appears you've hit the bottom. Only two ways for you to go
                from here: &nbsp;
                <a className={"iconNextArrow"} href="/">
                  See our work
                </a>{" "}
                or{" "}
                <a className={"iconNextArrow"} href="/contact">
                  say hello
                </a>{" "}
                <span className="spacer"></span>
              </span>
            </p>
          </div>
          <div className="mobile-socials">
            <a
              className={"social-link"}
              href="https://www.instagram.com/bythepublic/"
              target="_blank"
              rel="noreferrer">
              Instagram
            </a>
            <a
              className={"social-link"}
              href="https://www.linkedin.com/company/public-school-a-creative-agency/"
              target="_blank"
              rel="noreferrer">
              Linkedin
            </a>
            <a className={"social-link"} href="mailto:hello@public-school.com">
              hello@public-school.com
            </a>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div className="footerBottom">
            <div className="footerBottomLeft">
              <p>
                © Public School Agency, Dba of CDS. 2024.{" "}
                <a className={"iconNextArrow"} href="/privacy-policy">
                  Privacy
                </a>
                .
              </p>
            </div>
            <div className="footerBottomRight">
              <p>
                <a
                  className={"iconNextArrow"}
                  href="mailto:hello@public-school.com">
                  hello@public-school.com
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default InfoPrimary;
